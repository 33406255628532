import { Controller } from "@hotwired/stimulus";
import { initFlatpickr } from "../plugins/flatpickr";

export default class extends Controller {
  static targets = [
    "start",
    "end",
    "priceRule",
    "priceMonth",
    "priceWeek",
    "priceNight",
    "addPriceFor",
  ];

  connect() {
    this.togglePricePeriod();

    const defaultStart = this.startTarget.value
      ? new Date(this.startTarget.value)
      : new Date(this.element.dataset.defaultstart);

    const defaultEnd = this.endTarget.value
      ? new Date(this.endTarget.value)
      : new Date(defaultStart).fp_incr(7);

    const startOptions = {
      defaultDate: defaultStart,
      minDate: "today",
      onChange: (selectedDates, dateStr, instance) => {
        const selectedDate = new Date(dateStr);
        const endDate = new Date(selectedDate);
        endDate.setDate(endDate.getDate() + 1);
        this.endPicker.set("minDate", endDate);
        this.endPicker.jumpToDate(endDate);
      },
    };
    this.startPicker = initFlatpickr(this.startTarget, startOptions);

    const endOptions = {
      defaultDate: defaultEnd,
      minDate: defaultStart.setDate(defaultStart.getDate() + 1),
      onChange: (selectedDates, dateStr, instance) => {
        this.startPicker.set("maxDate", selectedDates[0]);
      },
    };
    this.endPicker = initFlatpickr(this.endTarget, endOptions);
  }

  updateEnd(event) {
    const nights = parseInt(event.target.value, 10);
    if (!isNaN(nights) && this.startTarget.value) {
      const newEndDate = new Date(this.startTarget.value);
      newEndDate.setDate(newEndDate.getDate() + nights);
      this.endPicker.setDate(newEndDate, true, "d/m/Y");
    }
  }

  enableDates() {
    this.startPicker.set("disable", []);
    this.endPicker.set("disable", []);
    this.startPicker.redraw();
    this.endPicker.redraw();
  }

  togglePricePeriod() {
    const priceRuleId = this.priceRuleTarget.value;
    if (!priceRuleId) return;

    const priceRules = JSON.parse(this.priceRuleTarget.dataset.priceRules);
    const selectedRule = priceRules.find((rule) => rule.id == priceRuleId);

    if (selectedRule && selectedRule.min_stay == 32) {
      this.priceMonthTarget.style.display = "block";
      this.priceWeekTarget.style.display = "none";
      this.priceNightTarget.style.display = "none";
    } else if (selectedRule && selectedRule.min_stay == 7) {
      this.priceWeekTarget.style.display = "block";
      this.priceMonthTarget.style.display = "none";
      this.priceNightTarget.style.display = "none";
    } else {
      this.priceNightTarget.style.display = "block";
      this.priceMonthTarget.style.display = "none";
      this.priceWeekTarget.style.display = "none";
    }

    if (
      priceRules.some(
        (priceRule) => priceRule.percentage && priceRule.percentage != 100
      ) &&
      selectedRule &&
      selectedRule.percentage == 100.0
    ) {
      this.addPriceForTarget.classList.remove("d-none");
    } else {
      this.addPriceForTarget.classList.add("d-none");
    }
  }

  calculateSuggestedPrice() {
    const priceRuleId = this.priceRuleTarget.value;
    const from = this.startTarget.value;
    const to = this.endTarget.value;

    if (!priceRuleId || !from || !to) return;

    const priceRules = JSON.parse(this.priceRuleTarget.dataset.priceRules);
    const selectedRule = priceRules.find((rule) => rule.id == priceRuleId);

    if (
      selectedRule &&
      selectedRule.percentage &&
      selectedRule.percentage !== "100.0"
    ) {
      this.fetchPriceForSelectedRule(priceRuleId, from, to);
    }
  }

  async fetchPriceForSelectedRule(priceRuleId, from, to) {
    const priceNightInput = this.priceNightTarget.querySelector("input");

    try {
      const response = await fetch(
        `/price_rules/${priceRuleId}/calculate_price?from=${from}&to=${to}`
      );

      if (!response.ok) {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }

      const data = await response.json();

      const suggestedPrice = data.suggested_price;

      if (suggestedPrice) {
        priceNightInput.value = Number(suggestedPrice).toFixed(2);
      }
    } catch (error) {
      console.error("Error fetching price for selected rule:", error);
    }
  }

  // currently updateDates is not used. See comment in PriceService rates_with_dates
  // async updateDates() {
  //   const priceRuleId = this.priceRuleTarget.value;
  //   if (!priceRuleId) return;

  //   try {
  //     const response = await fetch(
  //       `/price_rules/${priceRuleId}/dates_with_rates`
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP Error! Status: ${response.status}`);
  //     }

  //     const parsedResponse = await response.json();

  //     const datesWithRates = parsedResponse.unavailable_dates || [];
  //     const firstAvailableDate =
  //       parsedResponse.first_available_date || new Date();

  //     this.startPicker.set("disable", datesWithRates);
  //     this.endPicker.set("disable", datesWithRates);
  //     this.startPicker.set("defaultDate", firstAvailableDate);
  //   } catch (error) {
  //     console.error("Error fetching unavailable dates:", error);
  //   }
  // }
}
