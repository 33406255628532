import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader"];

  connect() {
    // Store bound methods to use the same function reference for removal
    this.boundShowLoader = this.showLoader.bind(this);
    this.boundHideLoader = this.hideLoader.bind(this);

    // Turbo-specific events
    document.addEventListener("turbo:submit-start", this.boundShowLoader);
    document.addEventListener("turbo:visit", this.boundShowLoader);
    document.addEventListener("turbo:load", this.boundHideLoader);
    document.addEventListener("turbo:frame-load", this.boundHideLoader);
    document.addEventListener("turbo:submit-end", this.boundHideLoader);
    document.addEventListener(
      "turbo:before-fetch-response",
      this.boundHideLoader
    );
    document.addEventListener(
      "turbo:fetch-request-error",
      this.boundHideLoader
    );

    // Standard browser navigation events
    window.addEventListener("load", this.boundHideLoader);
    window.addEventListener("pageshow", this.boundHideLoader);
    window.addEventListener("DOMContentLoaded", this.boundHideLoader);

    // Capture standard form submissions
    document.addEventListener("submit", this.boundShowLoader);

    // Handle AJAX requests
    this.setupAjaxListeners();

    // Handle browser back/forward
    window.addEventListener("popstate", this.boundHideLoader);

    // Add MutationObserver to detect dynamic content changes
    this.setupMutationObserver();

    // Handle initial page state
    if (document.readyState === "complete") {
      this.hideLoader();
    }
  }

  setupAjaxListeners() {
    // Intercept all XMLHttpRequest objects
    const originalXHROpen = XMLHttpRequest.prototype.open;
    const originalXHRSend = XMLHttpRequest.prototype.send;
    const self = this;

    XMLHttpRequest.prototype.open = function () {
      this.addEventListener("loadstart", self.boundShowLoader);
      this.addEventListener("load", self.boundHideLoader);
      this.addEventListener("error", self.boundHideLoader);
      this.addEventListener("abort", self.boundHideLoader);
      this.addEventListener("timeout", self.boundHideLoader);
      return originalXHROpen.apply(this, arguments);
    };

    // Intercept fetch API
    const originalFetch = window.fetch;
    window.fetch = function () {
      self.showLoader();
      return originalFetch
        .apply(this, arguments)
        .then((response) => {
          self.hideLoader();
          return response;
        })
        .catch((error) => {
          self.hideLoader();
          throw error;
        });
    };
  }

  setupMutationObserver() {
    // Watch for DOM changes that might indicate content loading
    this.observer = new MutationObserver((mutations) => {
      const contentChanged = mutations.some(
        (mutation) =>
          mutation.type === "childList" &&
          (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)
      );

      if (contentChanged) {
        this.hideLoader();
      }
    });

    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  showLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.style.display = "block";
    }
  }

  hideLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.style.display = "none";
    }
  }

  disconnect() {
    // Clean up Turbo event listeners
    document.removeEventListener("turbo:submit-start", this.boundShowLoader);
    document.removeEventListener("turbo:visit", this.boundShowLoader);
    document.removeEventListener("turbo:load", this.boundHideLoader);
    document.removeEventListener("turbo:frame-load", this.boundHideLoader);
    document.removeEventListener("turbo:submit-end", this.boundHideLoader);
    document.removeEventListener(
      "turbo:before-fetch-response",
      this.boundHideLoader
    );
    document.removeEventListener(
      "turbo:fetch-request-error",
      this.boundHideLoader
    );

    // Clean up standard browser listeners
    window.removeEventListener("load", this.boundHideLoader);
    window.removeEventListener("pageshow", this.boundHideLoader);
    window.removeEventListener("DOMContentLoaded", this.boundHideLoader);
    document.removeEventListener("submit", this.boundShowLoader);
    window.removeEventListener("popstate", this.boundHideLoader);

    // Clean up MutationObserver
    if (this.observer) {
      this.observer.disconnect();
    }

    // Restore original XHR and fetch
    if (XMLHttpRequest.prototype.originalOpen) {
      XMLHttpRequest.prototype.open = XMLHttpRequest.prototype.originalOpen;
    }

    if (window.originalFetch) {
      window.fetch = window.originalFetch;
    }
  }
}
