import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  clearFilter(event) {
    event.preventDefault();
    const inputFields = event.currentTarget
      .closest(".clear-filter-js")
      .querySelectorAll("input");

    inputFields.forEach((input) => {
      input.checked = false;
    });
  }
}
